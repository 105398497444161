import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import asyncComponent from "./components/asyncComponent";
import './App.scss';
import WebFont from 'webfontloader';
import { config } from "./config.js";
import * as Sentry from '@sentry/browser';

Sentry.init({
 dsn: "https://41f5990f3757499bb9b43eb48ce5c73e@sentry.io/1467024"
});

const Home = asyncComponent(() => import('./routes/home'));
const Suggest = asyncComponent(() => import('./routes/suggest'));
const List = asyncComponent(() => import('./routes/list')); 

WebFont.load({
  google: {
    families: ['Open Sans:400,700']
  }
});


class App extends Component {
  render() {
    document.title = config.ui.title; 
    return (
      <div>
        <Router>
        <div className="container">
          <Switch>
              <Route exact path='/' component={ Home } />
              <Route path="/view/:id" component={Home}/> 
              <Route path='/suggest' component={ Suggest } />
              <Route path='/list' component={ List } />
              <Redirect to="/" />
          </Switch>
        </div>
      </Router>
    </div>
    );
  }
}

export default App;
